import { getCookie } from "./cookie";
import { notFound } from "next/navigation";
import { callMessage } from "./callMessage";

// export const checkSuccess = (res: any) => {
//   if (typeof window !== "undefined" && getCookie("refreshToken") && res.auth === false) {
//     return res;
//   }
//   if (res.success === false && res.error_msg === "Вы не авторизованы") {
//     window.location.href = "/";
//   }
//   if (res && res.success) {
//     if (res.status && res.status === 404) {
//       if (typeof window !== "undefined") {
//         return notFound();
//       }
//     }
//     return res;
//   }
//   if (typeof window !== "undefined") callMessage("Ошибка!", res.error_msg, false);
//   return Promise.reject(res.error_msg);
// };

export const checkSuccess = (res: any) => {
  const isWindow = typeof window !== "undefined";
  if (isWindow && getCookie("refreshToken") && res.auth === false) {
    return res;
  }
  if (res.success === false && res.error_msg === "Вы не авторизованы") {
    if (isWindow) {
      window.location.href = "/";
    }
    return res;
  }
  if (res && res.success) {
    if (res.status && res.status === 404) {
      if (typeof window !== "undefined") {
        return notFound();
      }
    }
    return res;
  }
  if (isWindow) {
    callMessage("Ошибка!", res.error_msg, false);
  }
  return {
    ...res,
    success: false,
    error_msg: res.error_msg || "Неизвестная ошибка.",
    status: res.status || 500,
    error: {
      message: res.error_msg || "Неизвестная ошибка.",
      code: res.code || "UNKNOWN_ERROR",
      status: res.status || 500,
      data: res.data || null
    }
  };
};