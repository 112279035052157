export const sortOptions = [
  {
    value: "popularity",
    name: "Популярные",
  },
  {
    value: "priceasc",
    name: "Дешевле",
  },
  {
    value: "pricedesc",
    name: "Дороже",
  },
];

export const defaultCity = {
  code: "moskva",
  lat: "55.7522",
  lon: "37.6156",
  name: "Москва",
  default: true,
};
