import { sendErrorRequest } from "@/utils/api/send-error";
import { sendSentryError } from "@/utils/functions/sendSentryError";

export const requestError = (error: any, url: string, side: "server" | "client") => {
  sendErrorRequest(side, url, error);
  sendSentryError(error, url, undefined, side);
  return {
    success: false,
    error_msg: error.message || "Ошибка обращения к серверу.",
    status: error.response?.status || 500,
    error: {
      message: error.message || "Ошибка обращения к серверу.",
      code: error.code || "UNKNOWN_ERROR",
      status: error.response?.status || 500,
      data: error.response?.data || null,
      url: url,
    },
  };
};
