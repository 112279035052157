import { sendErrorRequest } from "../api/send-error";
import { sendSentryError } from "@/utils/functions/sendSentryError";

// export const checkResponse = (res: Response, url: string, side: "server" | "client") => {
//   return res.ok
//     ? res.json()
//     : res
//         .json()
//         .then((err) => {
//           sendErrorRequest(side, url || "без урла", err);
//           sendSentryError(err, url, res, side);
//           return Promise.reject(err);
//         })
//         .catch((err) => {
//           sendErrorRequest(side, res.url || url, err);
//           sendSentryError(err, url, res, side);
//           return Promise.reject(err);
//         });
// };

export const checkResponse = async (res: Response, url: string, side: "server" | "client") => {
  if (!res.ok) {
    try {
      const errorData = await res.json();
      sendErrorRequest(side, url, errorData);
      sendSentryError(errorData, url, res, side);
      return Promise.reject({
        ...res,
        success: false,
        error_msg: errorData.message || "Ошибка обращения к серверу.",
        status: res.status,
        error: {
          message: errorData.message || "Ошибка обращения к серверу.",
          code: errorData.code || "UNKNOWN_ERROR",
          status: res.status,
          data: errorData,
          url: url
        }
      });
    } catch (err) {
      sendErrorRequest(side, url, JSON.stringify(err));
      sendSentryError(err, url, res, side);
      return Promise.reject({
        ...res,
        success: false,
        error_msg: "Ошибка при получении данных.",
        status: res.status,
        error: {
          message: "Ошибка при получении данных.",
          code: "UNKNOWN_ERROR",
          status: res.status,
          data: null,
          url: url
        }
      });
    }
  }
  try {
    const data = await res.json();
    return data;
  } catch (err) {
    return {
      ...res,
      success: false,
      error_msg: "Пустой или некорректный ответ сервера.",
      status: res.status || 500,
      error: {
        message: "Пустой или некорректный ответ сервера.",
        code: "EMPTY_RESPONSE",
        status: res.status || 500,
        data: null,
        url: url
      }
    };
  }
};