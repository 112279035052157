"use client";
import { checkResponse } from "./check-response";
import { checkSuccess } from "./check-success";
import { getCookie } from "./cookie";
import { defaultCity } from "@/shared/helpers/data/sharedHelpers";
import { TRequest } from "@/shared/types/types";
import { requestError } from "@/utils/functions/requestError";
import { OHOTAKTIV_API_PROD } from "@/shared/helpers/data/globalHelper";

export async function requestClient(url: string, options?: TRequest) {
  const isCity = getCookie("choosedCity");
  const choosedCity = isCity ? JSON.parse(isCity) : defaultCity;
  const fullUrl = OHOTAKTIV_API_PROD + `${url}${url.includes("?") ? "&" : "?"}choosedCity=${choosedCity.code}`;
  const AUTH_TOKEN = options?.headers?.Authorization || getCookie("accessToken");
  try {
    const response = await fetch(fullUrl, {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: AUTH_TOKEN || "",
        "X-Geo-S": choosedCity.lon,
        "X-Geo-H": choosedCity.lat,
      },
      next: {
        revalidate: options?.next && typeof options?.next.revalidate === "number" ? options.next.revalidate : 2 * 60,
      },
    });
    const checkedResponse = await checkResponse(response, fullUrl, "client");
    return checkSuccess(checkedResponse);
  } catch (error: any) {
    return requestError(error, fullUrl, "client");
  }
}
