import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/app/(isolate)/index.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/header/components/Info/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Icon"] */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/components/Icon/index.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/pages/BlackFriday/components/Header/index.module.scss");
;
import(/* webpackMode: "eager" */ "/var/www/ohotaktiv/ohotaktiv.ru/src/shared/pages/BlackFriday/components/AppPromoFooter/index.module.scss");
