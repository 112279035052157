import { request } from "../functions/request";
import { ICard } from "@/shared/types/types";
export async function mindboxSubscribe(data: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.SubscriptionInFooter&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify(data)
  });
}
export async function mindboxViewProduct(id: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.ViewProduct&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      viewProduct: {
        product: {
          ids: {
            website: id
          }
        }
      }
    })
  });
}
export async function mindboxViewCategory(id: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.ViewCategory&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      viewProductCategory: {
        productCategory: {
          ids: {
            website: id
          }
        }
      }
    })
  });
}
export async function mindboxAddToWishList(id: any, price: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.AddToWishList&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      addProductToList: {
        product: {
          ids: {
            website: id
          }
        },
        pricePerItem: price
      }
    })
  });
}
export async function mindboxRemoveFromWishList(id: any, price: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.RemoveFromWishList&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      removeProductFromList: {
        product: {
          ids: {
            website: id
          }
        },
        pricePerItem: price
      }
    })
  });
}
export async function mindboxAddToBasket(id: any, price: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.AddToBasket&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      addProductToList: {
        product: {
          ids: {
            website: id
          }
        },
        pricePerItem: price
      }
    })
  });
}
export async function mindboxRemoveFromBasket(id: any, price: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.RemoveFromBasket&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      removeProductFromList: {
        product: {
          ids: {
            website: id
          }
        },
        pricePerItem: price
      }
    })
  });
}
export async function mindboxCreateUnauthorizedOrder(orderId: any, items: any, customer: any, amount: any, cartItems: any, coupon: any, isOnlinePay: any, orderInfo: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  const countPrice = items.reduce((acc: any, good: any) => acc + cartItems.find((item: ICard) => item.id === good.product_id).price * good.quantity, 0);
  const body = coupon ? {
    order: {
      ids: {
        WebsiteID: orderId
      },
      lines: items.map((good: any, index: number) => {
        return {
          basePricePerItem: `${cartItems.find((item: ICard) => item.id === good.product_id).price}.00`,
          discountedPricePerLine: `${cartItems.find((item: ICard) => item.id === good.product_id).price_discount}.00`,
          quantity: good.quantity,
          lineId: index,
          product: {
            ids: {
              Website: Number(good.product_id)
            }
          }
        };
      }),
      email: customer.email,
      mobilePhone: `+${customer.tel}`,
      payments: [{
        type: isOnlinePay ? 14 : 2,
        amount: Number(amount) / 100
      }],
      discounts: [{
        type: "promoCode",
        promoCode: {
          ids: {
            code: coupon
          }
        },
        amount: countPrice - Number(amount) / 100
      }],
      totalPrice: Number(amount) / 100
    },
    customer: {
      email: customer.email,
      lastName: customer.surname,
      firstName: customer.name,
      mobilePhone: `+${customer.tel}`
    }
  } : {
    order: {
      ids: {
        WebsiteID: orderId
      },
      lines: items.map((good: any, index: number) => {
        return {
          basePricePerItem: `${cartItems.find((item: ICard) => item.id === good.product_id).price}.00`,
          discountedPricePerLine: `${cartItems.find((item: ICard) => item.id === good.product_id).price_discount}.00`,
          quantity: good.quantity,
          lineId: index,
          product: {
            ids: {
              Website: Number(good.product_id)
            }
          }
        };
      }),
      email: customer.email,
      mobilePhone: `+${customer.tel}`,
      payments: [{
        type: isOnlinePay ? 14 : 2,
        amount: Number(amount) / 100
      }],
      totalPrice: Number(amount) / 100
    },
    customer: {
      email: customer.email,
      lastName: customer.surname,
      firstName: customer.name,
      mobilePhone: `+${customer.tel}`
    }
  };
  return await request(`/analytic/mindbox.php?request=async&operation=Website.CreateUnauthorizedOrder&deviceUUID=${deviceId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Mindbox secretKey="RTh6yZ1o696DtaSS8RDA"`
    },
    body: JSON.stringify(body)
  });
}
export async function mindboxCreateAuthorizedOrder(orderId: any, items: any, customer: any, amount: any, cartItems: any, coupon: any, isOnlinePay: any, orderInfo: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  const countPrice = items.reduce((acc: any, good: any) => acc + cartItems.find((item: ICard) => item.id === good.product_id).price * good.quantity, 0);
  const body = coupon ? {
    order: {
      ids: {
        WebsiteID: orderId
      },
      lines: items.map((good: any, index: number) => {
        return {
          basePricePerItem: `${cartItems.find((item: ICard) => item.id === good.product_id).price}.00`,
          discountedPricePerLine: `${cartItems.find((item: ICard) => item.id === good.product_id).price_discount}.00`,
          quantity: good.quantity,
          lineId: index,
          product: {
            ids: {
              Website: Number(good.product_id)
            }
          }
        };
      }),
      email: customer.email,
      mobilePhone: `+${customer.tel}`,
      payments: [{
        type: isOnlinePay ? 14 : 2,
        amount: Number(amount) / 100
      }],
      discounts: [{
        type: "promoCode",
        promoCode: {
          ids: {
            code: coupon
          }
        },
        amount: countPrice - Number(amount) / 100
      }],
      totalPrice: Number(amount) / 100
    },
    customer: {
      email: customer.email,
      lastName: customer.last_name,
      firstName: customer.name,
      mobilePhone: `+${customer.tel}`,
      ids: {
        WebsiteID: "{userId}"
      }
    }
  } : {
    order: {
      ids: {
        WebsiteID: orderId
      },
      lines: items.map((good: any, index: number) => {
        return {
          basePricePerItem: `${cartItems.find((item: ICard) => item.id === good.product_id).price}.00`,
          discountedPricePerLine: `${cartItems.find((item: ICard) => item.id === good.product_id).price_discount}.00`,
          quantity: good.quantity,
          lineId: index,
          product: {
            ids: {
              Website: Number(good.product_id)
            }
          }
        };
      }),
      email: customer.email,
      mobilePhone: `+${customer.tel}`,
      payments: [{
        type: isOnlinePay ? 14 : 2,
        amount: Number(amount) / 100
      }],
      totalPrice: Number(amount) / 100
    },
    customer: {
      email: customer.email,
      lastName: customer.last_name,
      firstName: customer.name,
      mobilePhone: `+${customer.tel}`,
      ids: {
        WebsiteID: "{userId}"
      }
    }
  };
  return await request(`/analytic/mindbox.php?request=async&operation=Website.CreateAuthorizedOrder&deviceUUID=${deviceId}&user=true`, {
    method: "POST",
    body: JSON.stringify(body)
  });
}
export async function mindboxRegisterCustomer(email: any, tel?: any, name?: any, lastName?: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  const body = tel ? {
    customer: {
      mobilePhone: tel,
      email,
      ids: {
        websiteID: "{userId}"
      },
      lastName: lastName || "",
      firstName: name || "",
      subscriptions: [{
        pointOfContact: "Email"
      }, {
        pointOfContact: "SMS"
      }]
    }
  } : {
    customer: {
      email: email,
      ids: {
        websiteID: "{userId}"
      },
      lastName: lastName || "",
      firstName: name || "",
      subscriptions: [{
        pointOfContact: "Email"
      }, {
        pointOfContact: "SMS"
      }]
    }
  };
  return await request(`/analytic/mindbox.php?request=async&operation=Website.RegisterCustomer&deviceUUID=${deviceId}&user=true`, {
    method: "POST",
    body: JSON.stringify(body)
  });
}
export async function mindboxEditSubscribes(pointOfContact: string, isSubscribed: boolean) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.EditCustomer&deviceUUID=${deviceId}&user=true`, {
    method: "POST",
    body: JSON.stringify({
      customer: {
        ids: {
          websiteID: "{userId}"
        },
        subscriptions: [{
          pointOfContact,
          isSubscribed
        }]
      }
    })
  });
}
export async function mindboxEditCustomer(customer: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=async&operation=Website.EditCustomer&deviceUUID=${deviceId}&user=true`, {
    method: "POST",
    body: JSON.stringify({
      customer: {
        lastName: customer.last_name,
        firstName: customer.name,
        ids: {
          websiteID: "{userId}"
        },
        customFields: {
          Address: customer.address
        }
      }
    })
  });
}
export async function mindboxGetSubscriptionInfo() {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=sync&operation=Website.GetSubscriptionInfo&deviceUUID=${deviceId}&user=true`, {
    method: "POST",
    body: JSON.stringify({
      customer: {
        ids: {
          websiteID: "{userId}"
        }
      }
    })
  });
}
export async function mindboxGetRelatedProducts(id: any) {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=sync&operation=RelatedProducts&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      recommendation: {
        limit: 6,
        product: {
          ids: {
            website: id
          }
        }
      }
    })
  });
}
export async function mindboxGetEventRecommendation() {
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=sync&operation=EventRecommendation&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      recommendation: {
        limit: 24
      }
    })
  });
}
export async function mindboxGetPromocode(category: string) {
  // ("use server");
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=sync&operation=${category}&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({})
  });
}
export async function mindboxGetLastSeenProducts() {
  "use client";
  const deviceCookie = document.cookie.split(";").filter(el => el.includes("mindboxDeviceUUID"));
  if (!deviceCookie.length) return;
  const deviceId = deviceCookie[0].split("=")[1];
  return await request(`/analytic/mindbox.php?request=sync&operation=LastViewedProducts&deviceUUID=${deviceId}`, {
    method: "POST",
    body: JSON.stringify({
      recommendation: {
        limit: 36
      }
    })
  });
}