export function googleRemoveFromWishList(id: any, price: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "remove_from_wishlist",
      ecommerce: {
        currency: "RUB",
        value: price,
        items: [{
          item_id: id
        }]
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleAddToWishList(id: any, price: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "add_to_wishlist",
      ecommerce: {
        currency: "RUB",
        value: price,
        items: [{
          item_id: id
        }]
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleAddToCart(id: any, price: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        currency: "RUB",
        value: price,
        items: [{
          item_name: name,
          item_id: id,
          price: price,
          item_category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : "",
          quantity: quantity
        }]
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleRemoveFromCart(id: any, price: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "remove_from_cart",
      ecommerce: {
        currency: "RUB",
        value: price,
        items: [{
          item_name: name,
          item_id: id,
          price: price,
          item_category: categories ? categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join("") : "",
          quantity: quantity
        }]
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleViewItem(id: any, price: any, quantity: any, categories: any, name: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [{
          item_name: name,
          item_id: id,
          price: price,
          item_category: categories && categories.map((category, index) => `${index !== 0 ? `/` : ``}${category.name}`).join(""),
          quantity: quantity
        }]
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googlePurchase(id: any, amount: any, cartItems: any, items: any) {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: id,
        value: Number(amount) / 100,
        currency: "RUB",
        // coupon: checkCouponInput.value,
        items: items.map((good, index) => {
          const findedItem = cartItems.find(item => item.id === good.product_id);
          return {
            item_name: findedItem.name,
            item_id: findedItem.id,
            price: findedItem.price_discount,
            item_category: findedItem.breadcrumb ? findedItem.breadcrumb.map((breadcrumb, index) => `${index !== 0 ? `/` : ``}${breadcrumb.name}`).join("") : "",
            index: index,
            quantity: good.quantity
          };
        })
      }
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleSendCallback() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "callback"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleViewStories() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "view_stories"
    });
  } catch (err) {
    console.log(err);
  }
}
// ждемс меня
export function googleAddToCartFromRec() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "cart_from_rec"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleAuth() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "authorization"
    });
  } catch (err) {
    console.log(err);
  }
}
// ждемс
export function googleReg() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "registration"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleFooterSubscription() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "footer_form"
    });
  } catch (err) {
    console.log(err);
  }
}
// ждемс
export function googleChooseCity(city: string) {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "choose_city"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleSendQuestion() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "ask_question"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleSendReview() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "send_review"
    });
  } catch (err) {
    console.log(err);
  }
}
export function googleSendAnalog() {
  try {
    window.dataLayer = window.dataLayer || [];
    return window.dataLayer.push({
      event: "analog"
    });
  } catch (err) {
    console.log(err);
  }
}