import styles from "./index.module.scss";
interface IToast {
  message: string;
  submessage: string;
}
const Toast = (props: IToast) => {
  const {
    message,
    submessage
  } = props;
  return <>
      {/* <h2 className={styles.title}>{message}</h2> */}
      <p className={styles.text}>{submessage}</p>
    </>;
};
export default Toast;