import * as Sentry from "@sentry/browser";

export const sendSentryError = async (err: any, url: any, res: any, side: "server" | "client") => {
  Sentry.captureException(err, {
    extra: {
      url,
      options: {
        err,
        side: side,
        res: res,
      },
    },
  });
};
