export function setCookie(name: string, value: string | boolean | number | undefined, props?: any) {
  props = props || {
    expires: 5184000
  };
  let exp = props.expires;
  if (typeof exp == "number" && exp) {
    const d = new Date();
    d.setTime(d.getTime() + exp * 1000);
    exp = props.expires = d;
  }
  if (exp && exp.toUTCString) {
    props.expires = exp.toUTCString();
  }
  value = encodeURIComponent(value!);
  let updatedCookie = name + "=" + value;
  for (const propName in props) {
    updatedCookie += "; " + propName;
    const propValue = props[propName];
    if (propValue !== true) {
      updatedCookie += "=" + propValue;
    }
  }
  document.cookie = updatedCookie + "; path=/";
}
export function getCookie(name: string) {
  const matches = document.cookie.match(new RegExp("(?:^|; )" +
  // eslint-disable-next-line no-useless-escape
  name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : false;
}
export const removeCookie = (name: string) => {
  setCookie(name, "", {
    expires: -1,
    path: "/"
  });
};