import { requestClient } from "./request-client";
import { requestServer } from "./request-server";
import { OHOTAKTIV_API_PROD } from "@/shared/helpers/data/globalHelper";
import { TRequest } from "@/shared/types/types";
export async function request(endpoint: string, options?: TRequest) {
  if (typeof window !== "undefined") {
    return requestClient(endpoint, options);
  } else {
    return requestServer(endpoint, options);
  }
}
export async function requestWithoutResponse(endpoint: string, options?: TRequest) {
  return fetch(OHOTAKTIV_API_PROD + endpoint, {
    ...options,
    headers: {
      ...options?.headers
    },
    next: {
      revalidate: 2 * 60
    }
  });
}